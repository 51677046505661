<template>
  <div
    :style="{
      minHeight: '600px',
      borderRadius: '5%',
    }"
    class="white pa-3"
  >
    <div :style="{ flex: '45%', margin: '0%' }">
      <v-card flat>
        <v-card-title> Top Clientes </v-card-title>
        <v-card-text>
          <div :style="{ marginBlock: '5px' }">
            <div
              :style="{
                display: 'flex',
                flexWrap: 'wrap',
                flex: 1,
                textAlign: 'left',
              }"
            >
              <div :style="{ flex: 1, fontWeight: 'bolder' }">
                Filtrar por fecha
              </div>
            </div>
            <div>
              <div
                :style="{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'column',
                  alignItems: 'left',
                }"
              >
                <div class="dx-field">
                  <div class="dx-field-label">Desde</div>
                  <div class="dx-field-value">
                    <date-picker
                      v-model="start"
                      @dateSelected="
                        (startP) => {
                          start = startP;
                        }
                      "
                      :value="start"
                      type="date"
                      :styling-mode="'underlined'"
                      :style="{ width: '150px' }"
                    ></date-picker>
                  </div>
                </div>

                <div>
                  <div class="dx-field" style="margin-bottom: 20px">
                    <div
                      class="dx-field-label"
                      style="display: inline-block; vertical-align: middle"
                    >
                      Hasta
                    </div>
                    <div class="dx-field-value" style="display: inline-block">
                      <date-picker
                        v-model="end"
                        @dateSelected="
                          (endP) => {
                            end = endP;
                          }
                        "
                        :value="end"
                        type="date"
                        :styling-mode="'underlined'"
                        :style="{ width: '150px' }"
                      ></date-picker>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <DxButton
                :width="100"
                text="aplicar"
                type="default"
                styling-mode="contained"
                @click="getTopCustomersRange()"
              />
            </div>
            <div
              :style="{
                display: 'flex',
                flexWrap: 'wrap',
                flex: 1,
                textAlign: 'center',
              }"
            >
            </div>
          </div>
          <div>
            <GeneralFilter
              :search="{}"
              :filterEndpoint="filterPromise"
              @filtered="filterHandler"
              @emptyFilter="clearFilter"
              style="width: 100%;"
            >
              <DxDataGrid
                :data-source="customerData"
                key-expr="ID_Customer"
                :scrolling="{ useNative: true }"    
                :column-auto-width="true"
                :show-row-lines="showRowLines"
                :show-borders="showBorders"
                :row-alternation-enabled="rowAlternationEnabled"
                style="overflow-x: auto;"
                @exporting="onExporting"
              >
                <DxExport :enabled="true" />
              
                <DxPaging :page-size="5" />

                <DxPager
                  :visible="true"
                  :show-page-size-selector="false"
                  :show-info="true"
                  :show-navigation-buttons="true"
                />

                <DxColumn
                  :minwidth="columnWidth"
                  data-field="ID_Customer"
                  caption="ID"
                />

                <DxColumn
                  :minwidth="columnWidth"
                  data-field="customerName"
                  caption="Nombre de Cliente"
                />
                
                <DxColumn
                  :minwidth="columnWidth"
                  data-field="email"
                  caption="Correo del Cliente"
                />

                <DxColumn
                  :minwidth="columnWidth"
                  data-field="typeBusiness"
                  caption="Es negocio"
                />
                
                <DxColumn
                  :minwidth="columnWidth"
                  data-field="amountFormatted"
                  caption="Monto"
                />
              </DxDataGrid>
            </GeneralFilter>
          </div>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxPager,
  DxExport,
} from "devextreme-vue/data-grid";
import GeneralFilter from "../GeneralFilter.vue";
import DxButton from "devextreme-vue/button";
import DatePicker from "../../components/DatePicker";
import { multipleCriteriaFilter } from "../../helpers/object";
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { saveAs } from 'file-saver-es';

ChartJS.register(ArcElement, Tooltip, Legend);
export default {
  name: "TopCustomerChart",
  components: {
    DxDataGrid,
    DxColumn,
    DatePicker,
    DxPaging,
    DxButton,
    DxPager,
    GeneralFilter,
    DxExport,
  },
  data() {
    return {
      start: new Date(),
      end: new Date(),
      panel: 0,
      showCustomer: false,
      topCustomers: [],
      topProducts: [],
      activeP: "dia",
      activeC: "dia",
      activePF: "Dias",
      countCustomers: [],
      showRowLines: true,
      showBorders: true,
      rowAlternationEnabled: true,
      search: {},
      showPageSizeSelector: true,
      topCustomersFiltered: [],
      isFiltered: false,
      dataP: {
        labels: ["Actual", "Anterior", "Dos anteriores"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E9351", "#00D6FF"],
            data: [10, 10, 10],
          },
        ],
      },
      Currency: null,
    };
  },
  mounted() {
    this.Currency = JSON.parse(localStorage.getItem("branch")).Currency;
    this.getTopCustomers();
    this.getTopProducts();
  },
  methods: {
    onExporting(e) {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet('TopClientes');

      exportDataGrid({
        component: e.component,
        worksheet,
        topLeftCell: { row: 4, column: 1 },
      }).then((cellRange) => {
        // header
        const headerRow = worksheet.getRow(2);
        headerRow.height = 30;
        worksheet.mergeCells(2, 1, 2, 5);

        headerRow.getCell(1).value = 'Top Clientes';
        headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 };
        headerRow.getCell(1).alignment = { horizontal: 'center' };

        // footer
        const footerRowIndex = cellRange.to.row + 2;
        const footerRow = worksheet.getRow(footerRowIndex);
        worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 5);

        footerRow.getCell(1).value = '-';
        footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true };
        footerRow.getCell(1).alignment = { horizontal: 'right' };
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'TopClientes.xlsx');
        });
      });
      e.cancel = true;
    },
    setCurrency() {
      this.topCustomers.map((customer) => {
        customer.amountFormatted = `${this.Currency}${customer.amount}`;
      });
    },
    filterHandler(response) {
      this.isFiltered = true;
      this.topCustomersFiltered = response;
    },
    clearFilter() {
      this.isFiltered = false;
    },
    filterPromise(filterObj) {
      const filterArray = [
        "ID_Customer",
        "BusinessName",
        "FirstName",
        "LastName",
        "BusinessEmail",
        "Email",
      ];
      return new Promise((resolve, reject) => {
        try {
          resolve(
            multipleCriteriaFilter(filterArray, filterObj, this.topCustomers)
          );
        } catch (error) {
          reject(error);
        }
      });
    },
    getTopCustomers() {
      this.$API.dashboard.getTop().then((response) => {
        this.topCustomers = response;
        this.activeC = "dia";
        this.setCurrency();
      });
    },
    getTopCustomersRange() {
      this.$API.dashboard
        .getTopCustomersRange(
          new Date(this.formatDate(this.start)),
          new Date(this.formatDate(this.end))
        )
        .then((response) => {
          this.topCustomers = response;
          this.activeC = "dia";
          this.setCurrency();
        });
    },
    formatDate(date) {
      const parts = date.split("/");
      const aux = parts[0];
      parts[0] = parts[1];
      parts[1] = aux;
      return parts.join("/");
    },
    getTopCustomersw() {
      this.$API.dashboard.getTopCustomersw().then((response) => {
        this.topCustomers = response;
        this.activeC = "semana";
        this.setCurrency();
      });
    },
    getTopCustomersm() {
      this.$API.dashboard.getTopCustomersm().then((response) => {
        this.topCustomers = response;
        this.activeC = "mes";
        this.setCurrency();
      });
    },
    getTopCustomersy() {
      this.$API.dashboard.getTopCustomersy().then((response) => {
        this.topCustomers = response;
        this.activeC = "año(actual)";
        this.setCurrency();
      });
    },
    //end pastel graphic
    getTopProducts() {
      this.$API.dashboard.getProducts().then((response) => {
        this.topProducts = response;
        this.setCurrency();
        this.activeP = "dia";
      });
    },
  },
  computed: {
    columnWidth() {    
      return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
    },
    customerData() {
      const customers = !this.isFiltered ? this.topCustomers : this.topCustomersFiltered
      return customers.map(customer => {
        return {
          ID_Customer: customer.ID_Customer,
          customerName: customer.Is_a_business ? customer.BusinessName : `${customer.FirstName} ${customer.LastName}`,
          typeBusiness: customer.Is_a_business ? 'Negocio' : 'Cliente',
          email: customer.Is_a_business ? customer.BusinessEmail :customer.Email,
          amountFormatted: customer.amountFormatted
        }
      })
    },
  },
};
</script>
<style scoped>
.number-statistics .c-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 40px;
  border-radius: 8px;
}

.dx-theme-generic-typography h3 {
  color: #000000;
}

.table-statistics .purple {
  background-color: purple;
}

.table-statistics .orange {
  background-color: orange;
}

.table-statistics h4 {
  color: #fff;
  padding: 10px 20px;
}

.dx-field {
  display: flex;
  align-items: center;
}
.dx-field-label {
  margin-right: 10px;
}

.dx-data-grid .dx-datagrid-headers, .dx-data-grid .dx-datagrid-rowsview {
  overflow-x: auto;
}
.dx-column{
  min-height: 150px;
}
</style>
